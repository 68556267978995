import Traec from "traec";
import { projectPermissionFilter } from "traec/utils/permissions/project";
import { setNavBarItems } from "traec-react/navBar";
import { mergeInUI } from "traec/redux/actionCreators";
import store from "traec/redux/store";
import { getTerm } from "AppSrc/tree/utils";

const reportPackageNavBarLinks = (props) => {
  let { isApportionmentProject } = props;
  let { wpackBase } = getBaseUrls(props);

  return [
    { label: "Dashboard", requiresAdmin: false, to: `${wpackBase}` },
    { label: "New report", requiresAdmin: false, to: `${wpackBase}/report` },
    { label: "Historic reports |", requiresAdmin: false, to: `${wpackBase}/evals` },
    // {
    //   label: getTerm("Reporting Package", props),
    //   requiresAdmin: false,
    //   to: [
    //     {
    //       label: "Dashboard",
    //       to: wpackBase,
    //       octicon: "home",
    //       requiresAdmin: false,
    //       requiredActions: ["READ_PROJECT_REPORT"]
    //     },
    //     {
    //       label: "Report due",
    //       to: `${wpackBase}/report`,
    //       octicon: "list-ordered",
    //       requiresAdmin: false,
    //       requiredActions: ["UPDATE_TRACKER_REF_SCORE_VALUE"]
    //     },
    //     {
    //       label: "Reports Submitted",
    //       to: `${wpackBase}/evals`,
    //       octicon: "checklist",
    //       requiresAdmin: false,
    //       requiredActions: ["READ_TRACKER_REF_SCORE_VALUE"]
    //     }
    //   ]
    // },
    // {
    //   label: "Admin",
    //   requiresAdmin: true,
    //   to: [
    //     { label: "Metrics", to: `${wpackBase}/metrics`, octicon: "server", requiresAdmin: true },
    //     { label: "Conversion Factors", to: `${wpackBase}/conv`, octicon: "package", requiresAdmin: true },
    //     { label: "Indicators", to: `${wpackBase}/indicators`, octicon: "issue-closed", requiresAdmin: true },
    //     isApportionmentProject
    //       ? {
    //           label: "Apportionment",
    //           to: `${wpackBase}/apportionment`,
    //           octicon: "list-unordered",
    //           requiresAdmin: true
    //         }
    //       : null,
    //     { label: "Settings", to: `${wpackBase}/details`, octicon: "gear", requiresAdmin: true }
    //   ]
    // }
  ];
};

const projectNavBarLinks = (props) => {
  let { projectId, rootRefId } = props;
  let { projectBase, wpackBase } = getBaseUrls(props);
  let projectRootBase = rootRefId ? `${projectBase}/wpack/${rootRefId.substring(0, 8)}` : `${projectBase}`;
  return [
    {
      label: "Project",
      requiresAdmin: false,
      to: [
        {
          label: "Dashboard",
          to: projectBase,
          octicon: "home",
          requiresAdmin: false,
          requiredActions: ["READ_PROJECT_REPORT"],
        },
        {
          label: "Members",
          to: `${projectBase}/members`,
          octicon: "organization",
          requiresAdmin: false,
          requiredActions: ["READ_PROJECT_MEMBER"],
        },
      ],
    },
    {
      label: "Admin",
      requiresAdmin: true,
      to: [
        { label: "Metrics", to: `${projectRootBase}/metrics`, octicon: "list-unordered", requiresAdmin: true },
        { label: "Conversion Factors", to: `${projectBase}/conv`, octicon: "package", requiresAdmin: true },
        { label: "Indicators", to: `${projectBase}/indicators`, octicon: "issue-closed", requiresAdmin: true },
        { label: "Settings", to: `${projectBase}/details`, octicon: "gear", requiresAdmin: true },
        { label: null },
        {
          label: "Email Settings",
          to: `/project/${projectId}/email/settings`,
          octicon: "inbox",
          requiresAdmin: true,
        },
        { label: "Email Statistics", to: `/project/${projectId}/email/report`, octicon: "mail", requiresAdmin: true },
      ],
    },
  ];
};

const getBaseUrls = (props) => {
  let { _projectId, _refId } = props.match.params;
  let { isRootRef: _isRootRef, rootRefId } = props;
  //
  let projectBase = `/project/${_projectId}`;
  let wpackBase = _refId ? `${projectBase}/wpack/${_refId}` : projectBase;
  let isRootRef = (_refId ? _isRootRef : true) || false;
  //
  return { projectBase, wpackBase, isRootRef };
};

// To be set using Redux state
export const navBarLinks = (props) => {
  const { projectId } = Traec.utils.getFullIds(store.getState(), props.match.params);
  let { isRootRef } = getBaseUrls(props);
  let links = isRootRef ? projectNavBarLinks(props) : reportPackageNavBarLinks(props);
  //console.log("GOT NAVBAR LINKS", projectId, isRootRef, links)
  return projectPermissionFilter(projectId, links);
};

export const setNavBar = (props) => {
  let { projectId } = props;
  let _navBarLinks = navBarLinks(props);
  store.dispatch(setNavBarItems(_navBarLinks));
  store.dispatch(
    mergeInUI(
      {
        id: projectId,
        type: "project",
      },
      {
        itemPath: "navbar",
      }
    )
  );
};
