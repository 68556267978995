import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Traec from "traec";

import { loadConvFacts } from "AppSrc/project/utils";

import TreeRow from "./treeRow";
import { Spinner } from "traec-react/utils/entities";
import { getPathChildren } from "traec/utils/nodes";
import { ErrorBoundary } from "traec-react/errors/handleError";

class MetricCategories extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTemplate: null,
    };

    this.requiredFetches = [
      //new Traec.Fetch("tracker_commit_edge", "read"),
      new Traec.Fetch("tracker_node", "list"),
      new Traec.Fetch("tracker_commit_branch", "list"),
      new Traec.Fetch("tracker_commit_convfactor", "list"),
    ];
  }

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  componentDidUpdate() {
    Traec.fetchRequiredFor(this);
  }

  render() {
    let { commitNodes, subTrees, convFactorDetails } = this.props;

    if (!commitNodes || !convFactorDetails) {
      return <Spinner title="Loading..." explanation="Loading Metrics. This might take a moment" />;
    }

    let categoryTrees = subTrees.map((tree, i) => {
      return <TreeRow {...this.props} key={i} path={tree.get("_path")} categoryName={tree.get("name")} />;
    });

    return <div className="container-fluid mt-3 mr-0 pr-0">{categoryTrees}</div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  let { cref, commitId, sortKey } = ownProps;

  let refId = cref ? cref.get("uid") : null;
  let trackerId = cref ? cref.get("tracker") : null;
  let commitNodes = commitId ? state.getInPath(`entities.commitNodes.${commitId}`) : null;
  let path = commitNodes ? commitNodes.get("pathRoot") : null;

  // Get the root Category Trees (excluding any folders holding meta-data such as conversion factors)
  let subTrees = getPathChildren(state, path, commitNodes, "trees")
    .filter((i) => i)
    .sortBy((i) => i.get(sortKey || "name"));

  // Get the conversion factors
  let convFactorMap = loadConvFacts(state, commitId);
  let convFactorDetails = { commitId, trackerId, convFactorMap };

  // Add this to props
  return { trackerId, refId, commitNodes, subTrees, convFactorDetails };
};

export default connect(mapStateToProps)(MetricCategories);
