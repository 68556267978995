import React from "react";

export const unitChoices = [
  "Number",
  "GBP (£)",
  "Percentage (%)",
  "tCO2e",
  "tCO2e per annum",
  "Tonnes",
  "tonnes",
  "kWh",
  "Credits",
  "Days",
  "Litres",
  "Miles",
  "M3",
  "Units",
  "kg",
  "Hours",
  "£/kWh",
  "£/L",
  "$/kWh",
  "$/L",
];

export const metricFields = {
  name: { value: "", class: "col-sm-8" },
  unit: {
    value: "",
    class: "col-sm-4",
    inputType: "select",
    endRow: true,
    options: unitChoices.map((name, i) => (
      <option key={i} value={name}>
        {name}
      </option>
    )),
  },
  //category: {value: '', inputType: "hidden", class: 'col-sm-8', endRow: true},
  description: {
    value: "",
    inputType: "tinymce",
    rows: "3",
    class: "col mb-1 mt-1",
    label: "",
    placeholder: "Body",
    config: {
      menubar: false,
      statusbar: false,
      content_css: "/static/bootstrap/css/bootstrap.css",
      plugins: "autolink link image lists print preview",
      block_formats: "Paragraph=p;Header 3=h3;Header 4=h4;Header 5=h5;",
      toolbar:
        "bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | sup sub | formatselect | removeformat",
    },
    endRow: true,
  },
};

export const documentFields = {
  title: { value: "", class: "col", endRow: true },
  description: {
    value: "",
    inputType: "tinymce",
    rows: "3",
    class: "col mb-1 mt-1",
    label: "",
    placeholder: "Body",
    config: {
      menubar: false,
      statusbar: false,
      content_css: "/static/bootstrap/css/bootstrap.css",
      plugins: "autolink link image lists print preview",
      block_formats: "Paragraph=p;Header 3=h3;Header 4=h4;Header 5=h5;",
      toolbar:
        "bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | sup sub | formatselect | removeformat",
    },
    endRow: true,
  },
};

export const conversionFields = {
  factor: { label: "", value: 0, inputType: "number", class: "col", endRow: true },
};

export const commitFields = {
  comment: { value: "", class: "col-sm-8" },
  reportingPeriod: {
    value: "",
    class: "col-sm-4",
    inputType: "select",
    endRow: true,
    options: [],
  },
};
