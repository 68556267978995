import React from "react";
import { connect } from "react-redux";
import { BSBtn, BSBtnDropdown } from "traec-react/utils/bootstrap";
import BaseFormConnected from "traec-react/utils/form";

import Traec from "traec";

import { cfFields } from "./form";
import TemplateSelect from "./templateSelect";
import { ErrorBoundary } from "traec-react/errors/handleError";
import { RightArrow } from "traec-react/utils/entities";

class CFRow extends React.Component {
  render() {
    let { convFact, hideGlobalColumn } = this.props;
    let fromBaseMetric = convFact.get("metric");
    return (
      <tr width="100%">
        <td>
          <span style={{ fontFamily: "monospace" }}>{fromBaseMetric.get("uid").substring(0, 8)}</span>
        </td>
        <td>{fromBaseMetric.get("name")}</td>
        <td>{fromBaseMetric.get("unit")}</td>
        <td>{convFact.get("toUnit")}</td>
        <td>{convFact.get("factor")}</td>
        {hideGlobalColumn ? null : <td>{convFact.get("is_global") ? "Y" : "N"}</td>}
      </tr>
    );
  }
}

class CFList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchedEdges: false,
      cfFormParams: {
        stateParams: {},
        fetchParams: {},
        initFields: {},
      },
      showAddTemplate: false,
      selectedTemplateId: "",
      selectedRefId: "",
    };

    this.requiredFetches = [new Traec.Fetch("tracker_node", "list")];

    // action bindings
    this.templateTrackerChange = this.templateTrackerChange.bind(this);
    this.templateRefChange = this.templateRefChange.bind(this);
    this.postAddFromTemplate = this.postAddFromTemplate.bind(this);
  }

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  componentDidUpdate() {
    Traec.fetchRequiredFor(this);
  }

  dropDownLinks() {
    let thisItems = [
      {
        name: "Add From Template",
        onClick: (e) => {
          this.setState({ showAddTemplate: true });
        },
      },
    ];
    return thisItems;
  }

  postAddFromTemplate(e) {
    e.preventDefault();
    let { trackerId, commitId } = this.props;
    new Traec.Fetch("tracker_commit_convfactor", "post", {
      trackerId,
      commitId,
      from_commit_id: this.state.selectedRefId,
    }).dispatch();
  }

  render_cfs() {
    let { convFactMap, hideGlobalColumn, isGlobal, emptyHelp } = this.props;
    if (!convFactMap) {
      return null;
    }

    // Filter the available conversion factors
    let rows = Object.keys(convFactMap)
      .filter((key) => convFactMap[key] && (isGlobal != null ? convFactMap[key].get("is_global") == isGlobal : true))
      .map((key, i) => <CFRow key={i} convFact={convFactMap[key]} hideGlobalColumn={hideGlobalColumn} />);

    // If there are no conversion factors to list then return some help text
    if (!rows.length && !(isGlobal == null)) {
      if (emptyHelp) {
        return emptyHelp;
      }
      return (
        <div>
          <p className="text-center">
            No locally defined conversion factors setup yet.
            <br />
            Add from a template using the "Set up Conversion Factors" <br />
            <b>or</b> define conversion factor overrides in the Admin <RightArrow /> Metrics menu.
          </p>
        </div>
      );
    }

    return (
      <table width="100%" className="table table-sm">
        <thead>
          <tr>
            <th width="10%">From Metric ID</th>
            <th width="50%">From Metric Name</th>
            <th width="10%">From Unit</th>
            <th width="10%">To Unit</th>
            <th width="10%">Value</th>
            {hideGlobalColumn ? null : <th width="10%">Global?</th>}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  }

  templateTrackerChange(e) {
    e.preventDefault();
    //console.log("SELECTED TEMPLATE ", e.target.value)
    this.setState({ selectedTemplateId: e.target.value });
  }

  templateRefChange(e) {
    e.preventDefault();
    //console.log("SELECTED REF ", e.target.value)
    this.setState({ selectedRefId: e.target.value });
  }

  render_add_from_template() {
    if (!this.state.showAddTemplate) {
      return null;
    }
    return (
      <TemplateSelect
        trackerSelectHandler={this.templateTrackerChange}
        selectRefHandler={this.templateRefChange}
        selectedTemplateId={this.state.selectedTemplateId}
        addFromTemplateHandler={this.postAddFromTemplate}
        submitHandler={this.postAddFromTemplate}
      />
    );
  }

  render() {
    let { hideSetupMenu } = this.props;
    return (
      <ErrorBoundary>
        {hideSetupMenu ? null : (
          <ErrorBoundary>
            <BSBtnDropdown links={this.dropDownLinks()} header={"Set up Conversion Factors"} />
            <div style={{ clear: "both" }} />
            <BaseFormConnected
              stateParams={this.state.cfFormParams.stateParams}
              fetchParams={this.state.cfFormParams.fetchParams}
              fields={cfFields}
            />
            {this.render_add_from_template()}
            <br />
          </ErrorBoundary>
        )}

        {this.render_cfs()}
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps)(CFList);
