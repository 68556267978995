import React from "react";
import { connect } from "react-redux";
import Traec from "traec";
import Moment from "moment";
import { downloadS3Object } from "./reportDocumentRow";
import { BSBtnDropdown } from "traec-react/utils/bootstrap";
import ReportRowErrorBoundary from "./error";
import { confirmDelete } from "traec-react/utils/sweetalert";

export const deleteDocumentObject = ({ trackerId, commitId, docId, docObjectId }) => {
  let fetch = new Traec.Fetch("tracker_commit_document_object", "delete", { trackerId, commitId, docId, docObjectId });
  confirmDelete({
    text: `This will delete the document object and all associated upload data.\n\n Are you sure you would like to proceed?`,
    onConfirm: () => {
      fetch.dispatch();
    },
  });
};

function DocumentHistorRow(props) {
  let { indentLevel, currentDocObject, commit } = props;

  if (!currentDocObject) {
    return null;
  }

  let filename = currentDocObject.get("filename");
  let is_staging = commit ? commit.get("is_staging") || commit.getInPath("status.name") === "Requires Revision" : null;

  return (
    <tr>
      <td>
        <p style={{ margin: "0", marginLeft: `${(indentLevel + 1) * 1.5}em` }}>
          {Moment(currentDocObject.get("created")).format("Do MMM YY, h:mm:ss a")}
        </p>
      </td>
      <td colSpan={4}>
        <a
          style={{
            cursor: "pointer",
            color: "rgb(0, 123, 255)",
            textDecoration: "underline",
          }}
          onClick={(e) => {
            e.preventDefault();
            downloadS3Object({ ...props, currentDocObject });
          }}
        >
          {filename}
        </a>
      </td>
      <td>
        {is_staging ? (
          <BSBtnDropdown
            links={[
              {
                name: `Delete`,
                onClick: (e) => {
                  deleteDocumentObject({ ...props, docObjectId: currentDocObject.get("uid") });
                },
              },
            ]}
          />
        ) : null}
      </td>
    </tr>
  );
}

class DocumentHistoryRows extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFiles: [],
      uploadedFile: false,
      showHistory: false,
    };

    this.requiredFetches = [
      new Traec.Fetch(
        "tracker_commit_document_object",
        "list",
        {},
        {
          preUpdateHook: (args) => ({ ...args, thisCommitOnly: true }),
        }
      ),
    ];
  }

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  componentDidUpdate() {
    Traec.fetchRequiredFor(this);
  }

  render() {
    let { docObjects, hide } = this.props;
    if (hide) {
      return null;
    }

    if (!docObjects || !docObjects.size) {
      return (
        <tr>
          <td colSpan={7} style={{ textAlign: "center" }}>
            <i>No Revisions of this File in this Report.</i>
          </td>
        </tr>
      );
    }
    return docObjects.map((obj, i) => (
      <ReportRowErrorBoundary key={i}>
        <DocumentHistorRow {...this.props} currentDocObject={obj} />
      </ReportRowErrorBoundary>
    ));
  }
}

const mapStateToProps = (state, ownProps) => {
  let { commitId, docId, path, currentDocObject } = ownProps;
  let docObjectIds =
    state.getInPath(`entities.commitEdges.byId.${commitId}.documents.${docId}.${path}.objects`) || Traec.Im.List();
  let currentDocId = currentDocObject ? currentDocObject.get("uid") : null;
  let docObjects = docObjectIds
    .filter((i) => i != currentDocId)
    .map((objId) => state.getInPath(`entities.docObjects.byId.${objId}`))
    .filter((i) => i)
    .sortBy((i) => i.get("created"))
    .reverse();
  return { docObjects };
};

export default connect(mapStateToProps)(DocumentHistoryRows);
