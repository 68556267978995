import React from "react";
import { BSBtn, BSBtnDropdown } from "traec-react/utils/bootstrap";
import Im from "traec/immutable";
import Traec from "traec";
import { alertSuccess } from "traec-react/utils/sweetalert";

export const SaveButtons = (props) => {
  return (
    <div className="mt-2 pt-2 float-right">
      <BSBtn
        extra_className="ml-2 pl-2"
        text={"Save to Current Reporting Period"}
        onClick={(e) =>
          saveToCurrentReportingPeriod(
            e,
            props.apportionments,
            props.projectId,
            props.currentReportingPeriod.get("uid")
          )
        }
      />
      <BSBtn
        extra_className="ml-2 pl-2"
        text={"Save to this and all future Reporting Periods"}
        onClick={(e) =>
          saveToCurrentReportingPeriod(
            e,
            props.apportionments,
            props.projectId,
            props.currentReportingPeriod.get("uid"),
            true
          )
        }
      />
    </div>
  );
};

export const saveToCurrentReportingPeriod = (e, apportionments, projectId, reportingPeriodId, future = false) => {
  apportionments = apportionments.toJS();
  e.preventDefault();
  let fetch = new Traec.Fetch("project_reporting_periods", "patch", { projectId, reportingPeriodId, future });
  fetch.updateFetchParams({
    body: { meta_json: { apportionment: { ...apportionments } } },
    postSuccessHook: () => alertSuccess({}),
  });
  fetch.dispatch();
};

export const ApportionedProjects = (props) => {
  let relatedProjects = [];

  let links = (index) => {
    return props.availableProjects.map((project) => {
      let client = Im.fromJS({
        project: { uid: project.get("uid"), name: project.get("name") },
        ref_id: project.getInPath("related_ref.uid"),
        value: null,
        companyName: project.getInPath("company.name"),
      });
      return {
        name: `${project.getInPath("company.name")} - ${project.get("name")}`,
        onClick: (e) => props.onChange(e, client, index),
      };
    });
  };
  try {
    relatedProjects = props.projects
      .filter((client) => {
        if (client !== "submitted") return client;
      })
      .map((client, index) => {
        return (
          <RelatedProject key={index} index={index} links={links(index)} client={client} onChange={props.onChange} />
        );
      });
  } catch (e) {
    return <div>Please add a client</div>;
  }
  if (!relatedProjects.size) {
    relatedProjects = Im.List([
      <div key={"empty"} className="mt-2 mb-2 pt-2 pb-2">
        Please add a Client
      </div>,
    ]);
  }

  relatedProjects = relatedProjects.push(
    <div key={"buttons"} className="mt-2 pt-2 float-left">
      <BSBtn extra_className="mr-2 pr-2" noFloatRight={true} text="Add Client" onClick={(e) => props.addClient(e)} />
      <BSBtn noFloatRight={true} text="Remove Client" onClick={(e) => props.removeClient(e)} />
    </div>
  );
  return relatedProjects;
};

export const RelatedProject = (props) => {
  let { client, index, links, onChange } = props;
  return (
    <div className="row pt-1 pb-1 mt-1 mb-1">
      <div className="col-5">
        <BSBtnDropdown
          floatStyle="float-left"
          header={client.getInPath("project.name") || "Please select a Client"}
          links={links.toJS()}
        />
      </div>
      <div className="col-5">{client.get("companyName") ? client.get("companyName") : null}</div>
      <div className="input-group col-2">
        <input
          style={{ height: "auto" }}
          className="form-control mt-0 mb-0 pt-0 pb-0"
          type="number"
          defaultValue={client.get("value") ? client.get("value") * 100 : null}
          onChange={(e) => onChange(e, client, index)}
        />
        <div className="input-group-append mt-0 mb-0 pt-0 pb-0">
          <span className="input-group-text mt-0 mb-0 pt-0 pb-0">%</span>
        </div>
      </div>
    </div>
  );
};

export const getReportingPeriod = (state, reportingPeriodId, projectId, refId) => {
  let reportingPeriods = state.getInPath(`entities.projectReportingPeriods.byId.${projectId}`);
  let currentReportingPeriod = Im.Map();
  let currentReportingPeriodId = null;

  if (!reportingPeriodId) {
    try {
      let newReportingPeriod = state.getInPath(`entities.refs.byId.${refId}.latest_commit.reporting_period_data`);
      currentReportingPeriodId = newReportingPeriod.get("previous")
        ? newReportingPeriod.get("previous")
        : newReportingPeriod.get("uid");

      currentReportingPeriod = reportingPeriods.get(currentReportingPeriodId);
    } catch (e) {}
  } else {
    try {
      currentReportingPeriodId = reportingPeriodId;
      currentReportingPeriod = reportingPeriods.get(currentReportingPeriodId);
    } catch (e) {}
  }

  return { reportingPeriods, currentReportingPeriod, currentReportingPeriodId };
};
