import React, { useEffect } from "react";
import { BSBtn, BSBtnDropdown, BSCard } from "traec-react/utils/bootstrap";
import Traec from "traec";
import { connect } from "react-redux";
import Im from "traec/immutable";
import Moment from "moment";

const AcceptedRequestPanel = (props) => {
  let { acceptedRequests, refs, projectId } = props;

  useEffect(() => {
    Traec.fetchRequiredFor({
      props,
      requiredFetches: [new Traec.Fetch("project", "list"), new Traec.Fetch("project_discipline", "list")],
    });
  });

  return (
    <BSCard
      title="My Clients"
      body={
        <div>
          <AcceptedRequests acceptedRequests={acceptedRequests} projectId={projectId} />
        </div>
      }
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  let { projectId } = ownProps;
  let availableProjects = state.getInPath("entities.projects.byId").filter((project) => {
    if (project.getInPath("meta_json.allowSuppliers")) {
      return project;
    }
  });
  let projectDisciplines = state.getInPath(`entities.projectObjects.byId.${projectId}.disciplines`);
  let requests = state.getInPath(`entities.projectObjects.byId.${projectId}.requests`);
  let acceptedRequests = requests ? requests.filter((request) => request.get("status") === "accepted") : Im.Map();

  return {
    availableProjects,
    projectDisciplines,
    acceptedRequests,
  };
};

export default connect(mapStateToProps)(AcceptedRequestPanel);

const AcceptedRequests = (props) => {
  let { acceptedRequests, projectId } = props;

  try {
    let requestRows = acceptedRequests
      .valueSeq()
      .map((item, index) => (
        <RequestRow key={index} refId={item.get("client_ref")} request={item} projectId={projectId} />
      ));
    if (requestRows.size) {
      requestRows = requestRows.toList().insert(0, <RequestRowHeader key={-1} />);
      return requestRows;
    } else {
      return <p>No Clients</p>;
    }
  } catch (e) {
    return <p>No Clients</p>;
  }
};

const RequestRowHeader = () => {
  return (
    <div className="row mb-2 mt-1 pt-1 pb-2" style={{ borderBottom: "1px solid lightgrey" }}>
      <div className="col-3">
        <b>Client</b>
      </div>
      <div className="col-4">
        <b>Company Name</b>
      </div>
      <div className="col-2">
        <b>Your Role</b>
      </div>
      <div className="col-2">
        <b>Client Since</b>
      </div>
      <div className="col-1">
        <b>Admin</b>
      </div>
    </div>
  );
};

const RequestRow = (props) => {
  let { request, projectId } = props;

  return (
    <div className="row">
      <div className="col-3">{request.getInPath("client_project.name")}</div>
      <div className="col-4">{request.getInPath("client_project.company.name")}</div>
      <div className="col-2">{request.getInPath("supplier_discipline.name")}</div>
      <div className="col-2">{Moment(request.get("created")).format("Do MMM YY")}</div>
      <div className="col-1">
        <BSBtnDropdown
          links={[
            {
              name: "Delete",
              onClick: (e) =>
                new Traec.Fetch("project_supplier_sent", "delete", {
                  projectId,
                  requestId: request.get("uid"),
                }).dispatch(),
            },
          ]}
        />
      </div>
    </div>
  );
};
