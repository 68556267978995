import React from "react";
import { connect } from "react-redux";
import Moment from "moment";
import { BSBtn } from "traec-react/utils/bootstrap";
import { BreadCrumb, getProjectProps } from "AppSrc/project/utils";
import { Spinner } from "traec-react/utils/entities";
import Traec from "traec";
import Im from "traec/immutable";
import Octicon from "react-octicon";
import { alertSuccess } from "traec-react/utils/sweetalert";

export class ProjectIndicatorTarget extends React.Component {
  constructor(props) {
    super(props);

    this.state = { targetDidLoad: false };
    this.updateTarget = this.updateTarget.bind(this);
    this.updateReportingPeriod = this.updateReportingPeriod.bind(this);
    this.distributeValues = this.distributeValues.bind(this);

    this.requiredFetches = [
      new Traec.Fetch("tracker_commit_indicator", "list"),
      new Traec.Fetch("tracker_commit_target", "list"),
      new Traec.Fetch("project_reporting_periods", "list"),
    ];
  }

  componentDidMount() {
    Traec.fetchRequiredFor(this);
    this.updateTargetsInState();
  }

  componentDidUpdate() {
    Traec.fetchRequiredFor(this);
    this.updateTargetsInState();
  }

  updateTargetsInState() {
    let { reportingPeriods, metricTarget } = this.props;
    if (reportingPeriods && metricTarget && metricTarget.size && !this.state.targetDidLoad) {
      this.setState({
        target: metricTarget.get("value"),
        targetDidLoad: true,
        ...getTargetsFromMetaJson(metricTarget.get("meta_json").toJS(), reportingPeriods),
        threshold: this.getThresholdFromTarget(metricTarget),
        greenBelow: metricTarget.getIn(["meta_json", "greenBelow"]),
      });
    }
  }

  updateReportingPeriod(e, reportingPeriod, targetType) {
    e.preventDefault();
    let reportingPeriodId = reportingPeriod.get("uid");
    let period = this.state[reportingPeriodId];
    this.setState({
      [reportingPeriodId]: Object.assign(period, { [targetType]: Number(e.target.value) }),
    });
  }

  getThresholdFromTarget(target) {
    return target.getInPath("meta_json.thresholdLow") || target.getInPath("meta_json.thresholdHigh");
  }

  renderReportingPeriodRows() {
    let { reportingPeriods } = this.props;

    if (!reportingPeriods) {
      return (
        <div className="col-7 pt-2">
          <Spinner explanation="Loading Reporting Periods" timedOutComment="Could not load Reporting Periods" />
        </div>
      );
    }

    let sortedPeriods = reportingPeriods.sortBy((reportingPeriod) => reportingPeriod.get("startDate")).valueSeq();

    return sortedPeriods.map((reportingPeriod, index) => {
      let period = this.state[reportingPeriod.get("uid")];
      return (
        <ReportingPeriodRow
          key={index}
          reportingPeriod={reportingPeriod}
          target={period ? period["target"] : 0}
          threshold={period ? period["threshold"] : 0}
          updateReportingPeriod={this.updateReportingPeriod}
        />
      );
    });
  }

  renderTitle() {
    let { indicator, company, project } = this.props;

    let indicatorName = indicator ? indicator.get("name") : "Indicator";

    return (
      <div className="mb-3 pb-3">
        <h3>{`Indicator Target: ${indicatorName}`}</h3>
        <BreadCrumb company={company} project={project} isRootRef={this.props.isRootRef} cref={this.props.cref} />
      </div>
    );
  }

  renderHeader() {
    return (
      <div className="col-12 pb-1">
        <div className="row">
          <div className="col-3" />
          <div className="col-1">Target</div>
          <div className="col-1">Threshold</div>
        </div>
      </div>
    );
  }

  updateTarget(e, targetType) {
    e.preventDefault();
    this.setState({ [targetType]: e.target.value });
  }

  distributeValues(e) {
    e.preventDefault();
    let target = this.state.target;
    let threshold = this.state.threshold;
    let num_reportingPeriods = this.props.reportingPeriods.size;
    let sortedPeriods = this.props.reportingPeriods.sortBy((reportingPeriod) => reportingPeriod.get("startDate"));
    let runningTarget = 0;
    let runningThreshold = 0;
    sortedPeriods.map((reportingPeriod) => {
      runningTarget = runningTarget + (target / num_reportingPeriods ? target / num_reportingPeriods : 0);
      runningThreshold = runningThreshold + (threshold / num_reportingPeriods ? threshold / num_reportingPeriods : 0);
      this.setState({ [reportingPeriod.get("uid")]: { target: runningTarget, threshold: runningThreshold } });
    });
  }

  render() {
    return (
      <div className="container-fluid">
        {this.renderTitle()}
        <FunctionalityHeader
          greenBelow={this.state.greenBelow}
          onButtonClick={(e) => this.setState({ greenBelow: !this.state.greenBelow })}
          trackerId={this.props.trackerId}
          commitId={this.props.commitId}
          projectId={this.props.projectId}
          metricTarget={this.props.metricTarget}
          baseMetricId={this.props.baseMetricId}
          target={this.state.target}
          threshold={this.state.threshold}
          targets={getTargetsFromMetaJson(this.state)}
          isRootRef={this.props.isRootRef}
          refId={this.props.crefId}
          reportingPeriods={this.props.reportingPeriods}
        />
        {this.renderHeader()}
        <ProjectTarget
          updateTarget={this.updateTarget}
          distributeValues={this.distributeValues}
          target={this.state.target}
          threshold={this.state.threshold}
        />
        <div className="col-12">{this.renderReportingPeriodRows()}</div>
      </div>
    );
  }
}

export const mapStateToProps = (state, ownProps) => {
  //const { _projectId, indicatorId, _refId } = ownProps.match.params;
  const { indicatorId } = ownProps.match.params;
  const { projectId, refId } = Traec.utils.getFullIds(state, ownProps.match.params);

  let { company, project, trackerId, cref, crefId, isRootRef } = getProjectProps(state, projectId, refId);
  let commitId = cref ? cref.getInPath("latest_commit.uid") : null;
  let indicator = state.getInPath(`entities.commitEdges.byId.${commitId}.indicators.${indicatorId}`);
  let reportingPeriods = state.getInPath(`entities.projectReportingPeriods.byId.${projectId}`);
  let baseMetricId = state.getInPath(
    `entities.commitEdges.byId.${commitId}.indicators.${indicatorId}.resultBaseMetric.uid`
  );
  let metricTarget = getMetricTarget(state, baseMetricId, commitId);

  return {
    projectId,
    project,
    company,
    indicatorId,
    reportingPeriods,
    indicator,
    commitId,
    trackerId,
    baseMetricId,
    metricTarget,
    isRootRef,
    crefId,
    cref,
    include_summary: true,
    include_commit_results: false,
  };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(ProjectIndicatorTarget);

const FunctionalityHeader = (props) => {
  return (
    <div>
      <div className="col-12 pb-2">
        Desired performance above or below the targets and threshold?
        <BSBtn
          extra_className="ml-2 pl-2"
          noFloatRight={true}
          primaryOff={!!props.greenBelow}
          text={<Octicon name="arrow-up" />}
          onClick={props.onButtonClick}
        />
        <BSBtn
          noFloatRight={true}
          primaryOff={!props.greenBelow}
          text={<Octicon name="arrow-down" />}
          onClick={props.onButtonClick}
        />
      </div>
      <div className="col-12 pb-2">
        <SaveCumulativeTarget
          trackerId={props.trackerId}
          commitId={props.commitId}
          projectId={props.projectId}
          refId={props.refId}
          isRootRef={props.isRootRef}
          metricTarget={props.metricTarget}
          baseMetricId={props.baseMetricId}
          target={props.target}
          greenBelow={props.greenBelow}
          threshold={props.threshold}
          targets={props.targets}
          reportingPeriods={props.reportingPeriods}
        />
      </div>
    </div>
  );
};

const SaveCumulativeTarget = (props) => {
  let {
    projectId,
    refId,
    isRootRef,
    trackerId,
    commitId,
    metricTarget,
    baseMetricId,
    target,
    targets,
    greenBelow,
    threshold,
    reportingPeriods,
  } = props;

  const indicatorLink = () => {
    if (isRootRef) {
      return `/project/${projectId}/indicators`;
    } else {
      return `/project/${projectId}/wpack/${refId}/indicators`;
    }
  };

  const updateCumulativeTarget = (e) => {
    e.preventDefault();
    let fetch = new Traec.Fetch("tracker_commit_target", "post", {
      trackerId: trackerId,
      commitId: commitId,
    });

    if (props.metricTarget && props.metricTarget.size) {
      fetch = new Traec.Fetch("tracker_commit_target", "patch", {
        trackerId: trackerId,
        commitId: commitId,
        metricTargetId: metricTarget.get("uid"),
      });
    }

    let targetsByDate = reportingPeriodTargetstoList(targets, reportingPeriods);

    fetch.updateFetchParams({
      preFetchHook: (data) => ({
        metric: baseMetricId,
        value: target,
        date: Moment(),
        meta_json: {
          byDate: targetsByDate,
          greenBelow: greenBelow,
          thresholdLow: threshold,
        },
      }),
      postSuccessHook: (data) => {
        alertSuccess({
          text: `Thank you, the target has been updated`,
          onConfirm: () => {
            location.href = indicatorLink();
          },
        });
      },
    });
    fetch.dispatch();
  };

  return <BSBtn noFloatRight={true} text={"Save"} onClick={(e) => updateCumulativeTarget(e)} />;
};

const ProjectTarget = (props) => {
  let { target, threshold, updateTarget, distributeValues } = props;
  return (
    <div className="col-12 pb-2">
      <div className="row">
        <div className="col-3">
          <b>Project Target and Threshold:</b>
        </div>
        <input
          className="col-1 mr-2 form-control p-1"
          defaultValue={target}
          onChange={(e) => updateTarget(e, "target")}
        />
        <input
          className="col-1 mr-2 form-control p-1"
          defaultValue={threshold}
          onChange={(e) => updateTarget(e, "threshold")}
        />
        <BSBtn onClick={distributeValues} text={"Distribute across Reporting Periods"} />
      </div>
    </div>
  );
};

export const ReportingPeriodRow = (props) => {
  let { reportingPeriod, target, threshold, updateReportingPeriod } = props;
  return (
    <div className="row mb-2">
      <div className="col-1">{Moment(reportingPeriod.get("startDate")).format("Do MMM YY")}</div>
      <div className="col-1">{Moment(reportingPeriod.get("endDate")).format("Do MMM YY")}</div>
      <div className="col-1" />
      <input
        className={`col-1 mr-2 form-control`}
        placeholder={target.toFixed(2)}
        onChange={(e) => updateReportingPeriod(e, reportingPeriod, "target")}
      />
      <input
        className={`col-1 mr-2 form-control`}
        placeholder={threshold.toFixed(2)}
        onChange={(e) => updateReportingPeriod(e, reportingPeriod, "threshold")}
      />
    </div>
  );
};

const getReportingPeriodForDate = (dateStr, reportingPeriods) => {
  let date = Moment(dateStr);
  let closest = {
    dt: null,
    period: null,
  };
  for (let reportingPeriod of reportingPeriods) {
    let endDate = Moment(reportingPeriod.get("endDate"));
    let dt = endDate.diff(date, "days");
    if (dt == 0) {
      closest = { dt, period: reportingPeriod };
      break;
    } else if (dt < 0) {
      continue;
    } else if (!closest.dt || (closest.dt && dt < closest.dt)) {
      closest = { dt, period: reportingPeriod };
    }
  }
  return closest.period;
};

export const targetToReportingPeriodMap = (targetDates, reportingPeriods) => {
  let targets = {};
  for (let target of targetDates) {
    let reportingPeriod = getReportingPeriodForDate(target["endDate"], reportingPeriods);
    if (reportingPeriod) {
      let reportingPeriodId = reportingPeriod.get("uid");
      //console.log("MATCHED REPORTING PERIOD TO TARGET", reportingPeriodId, target)
      targets[reportingPeriodId] = target;
    }
  }
  return targets;
};

export const reportingPeriodTargetstoList = (targets, reportingPeriods) => {
  let targetList = [];
  for (let [key, value] of Object.entries(targets)) {
    let rp = reportingPeriods.get(key);
    targetList.push({
      ...value,
      startDate: rp.get("startDate"),
      endDate: rp.get("endDate"),
    });
  }
  return targetList;
};

export const getTargetsFromMetaJson = (state, reportingPeriods) => {
  let targets = {};

  if (state["byDate"]) {
    return targetToReportingPeriodMap(state["byDate"], reportingPeriods.toList());
  }

  for (let key in state) {
    if (key.match(/[0-9a-f-]{36}/g)) {
      targets[key] = state[key];
    }
  }

  return targets;
};

export const getMetricTarget = (state, baseMetricId, commitId) => {
  let metricTargets = state.getInPath(`entities.commitEdges.byId.${commitId}.metricTargets`);
  try {
    let metricTarget = metricTargets.filter((target) => {
      if (target.getInPath("metric.uid") === baseMetricId) {
        return target;
      }
    });
    return metricTarget.first();
  } catch (e) {
    return Im.Map();
  }
};
