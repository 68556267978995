import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Traec from "traec";

import { BSBtnDropdown } from "traec-react/utils/bootstrap";
import Octicon from "react-octicon";

import BaseFormConnected from "traec-react/utils/form";
import { confirmDelete } from "traec-react/utils/sweetalert";
import { metricFields, frequencyFields } from "./forms";
import { metricCounters } from "./index";

import { getNodeFromPath } from "traec/utils/nodes";
import { SubNodes } from "./node";
import { ErrorBoundary } from "traec-react/errors/handleError";
import { copyToCommitHandler } from "./utils";
import { setAndShowModal } from "AppSrc/utils/modal";
import { SetNodeMetaJsonInput, MetricFrequency } from "./metricRow";

import { DragDropHandle } from "./dragHandle";

const MODAL_ID = "CommonTreeModal001";

function AdminMenu(props) {
  let { copyToCommit, onAddClick, links } = props;
  if (copyToCommit) {
    return (
      <a
        className="btn btn-sm btn-default m-0 p-0"
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          copyToCommitHandler(props);
        }}
      >
        Add
      </a>
    );
  }
  return <BSBtnDropdown links={links} header={<Octicon name="gear" />} />;
}

const lsKey = (path = "", refId = "") => {
  let key = `${refId.substring(0, 8)}-${(path || "").substring(7)}`;
  return key;
};

const addMetricModal = (props) => {
  let modalId = "CommonMetricModal001";
  let { trackerId, refId, commitId, path, categoryName } = props;

  console.log("Adding metric for category", categoryName);

  let fetch = new Traec.Fetch("tracker_node", "post", { trackerId, refId, commitId, path });
  fetch.updateFetchParams({
    preFetchHook: (body) => ({
      type: "metricscore",
      path,
      node: {
        metricscore: {
          metric: {
            ...body,
            category: categoryName,
          },
        },
      },
    }),
    postSuccessHook: () => {
      $(`#${modalId}`).modal("hide");
    },
  });

  setAndShowModal(modalId, {
    title: "Add a metric",
    body: <BaseFormConnected params={fetch.params} fields={metricFields} forceShowForm={true} hideUnderline={true} />,
  });
};

const deleteTree = (props) => {
  let { trackerId, refId, commitId, tree, path: pathId } = props;
  let treeName = tree ? tree.get("name") : null;

  confirmDelete({
    text: `This will delete the Category: ${treeName} including any sub-metrics and documents contained within.  Are you sure you would like to proceed?`,
    onConfirm: () => {
      new Traec.Fetch("tracker_node", "delete", { trackerId, refId, commitId, pathId }).dispatch();
    },
  });
};

function editTree(props) {
  let { tree, trackerId, refId, commitId, path: pathId } = props;
  let modalId = "CommonMetricModal001";
  if (!tree) {
    return null;
  }

  let fetch = new Traec.Fetch("tracker_node", "put", {
    trackerId,
    refId,
    commitId,
    pathId,
  });
  fetch.updateFetchParams({
    preFetchHook: (data) => ({
      type: "tree",
      node: {
        tree: data,
      },
    }),
    postSuccessHook: () => {
      $(`#${modalId}`).modal("hide");
    },
  });

  setAndShowModal(modalId, {
    title: "Edit issue name",
    body: (
      <BaseFormConnected
        params={fetch.params}
        fields={{ name: { value: "", endRow: true } }} // This is converted to Immutable when Modal props are stored in Redux
        initFields={tree}
        forceShowForm={true}
        hideUnderline={true}
      />
    ),
  });
}

const editFrequency = (props) => {
  let { tree, trackerId, refId, commitId, path: pathId } = props;

  let fetch = new Traec.Fetch("tracker_node", "patch", {
    trackerId,
    refId,
    commitId,
    pathId,
  });
  fetch.updateFetchParams({
    preFetchHook: (data) => {
      let _data = {
        type: "tree",
        node: {
          tree: {
            meta_json: {
              freq_num: data.freq_num || null,
              freq_unit: data.freq_unit || null,
              from_date: data.from_date || null,
            },
          },
        },
      };
      console.log("POSTING DATA", _data);
      return _data;
    },
    postSuccessHook: () => $(`#${MODAL_ID}`).modal("hide"),
  });

  setAndShowModal(MODAL_ID, {
    title: "Edit Reporting Frequency",
    body: (
      <BaseFormConnected
        params={fetch.params}
        fields={Traec.Im.fromJS(frequencyFields).toJS()}
        initFields={tree.get("meta_json")}
        forceShowForm={true}
        hideUnderline={true}
      />
    ),
  });
};

const editMetajson = (props) => {
  let modalId = "CommonNodeMetaModal001";
  setAndShowModal(modalId, {
    title: "Edit node meta-data",
    immutableBodyProps: true,
    body: <SetNodeMetaJsonInput {...props} modalId={modalId} node={props.tree} nodeType={"tree"} />,
  });
};

export class TreeRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false, //localStorage.getItem(lsKey(props.path, refId)) === "true"
    };

    this.copyTreeToCommit = this.copyTreeToCommit.bind(this);
  }

  /**********************
    DROPDOWN MENUS 
    **********************/

  dropDownLinks() {
    let thisItems = [
      {
        name: "Add Metric",
        onClick: (e) => addMetricModal(this.props),
      },
      {
        name: "Edit Issue Name",
        onClick: () => {
          editTree(this.props);
        },
      },
      {
        name: "Set Frequency",
        onClick: () => {
          editFrequency(this.props);
        },
      },
      {
        name: "Edit raw meta-data",
        onClick: () => {
          editMetajson(this.props);
        },
      },
      { name: null },
      { name: "Delete", onClick: (e) => deleteTree(this.props) },
    ];
    let extraDropDowns = this.props.extraDropDowns || [];
    return extraDropDowns.concat(thisItems);
  }

  /**********************
    MENU OPERATIONS 
    **********************/

  copyTreeToCommit(e) {
    e.preventDefault();
    let { commitId: fromCommitId, copyToCommit, parentTreeId, rootTreeId } = this.props;

    // Get the tracker and commit ID to the new commit
    let { trackerId, commitId } = copyToCommit;

    let fetch = new Traec.Fetch("tracker_commit_edge", "put", { trackerId, commitId });
    fetch.updateFetchParams({
      body: {
        edge_type: "treetree",
        parent_id: parentTreeId,
        child_id: rootTreeId,
        from_commit: fromCommitId,
      },
    });

    fetch.dispatch();
  }

  /**********************
    RENDER METHODS 
    **********************/

  render() {
    let { tree, refId, trackerId, categoryName, path, sortKey } = this.props;
    if (!tree) {
      return null;
    }

    metricCounters.row = 0;
    let { collapsed } = this.state;
    let caret = collapsed ? "triangle-right" : "triangle-down";

    console.log("Rendering Tree", tree.get("name"));
    return (
      <ErrorBoundary>
        <div className="row" style={{ borderTop: "1px solid #ddd" }}>
          <div className="col-sm-6">
            <DragDropHandle trackerId={trackerId} path={path} />
            <span
              onClick={(e) => {
                localStorage.setItem(lsKey(path, refId), !collapsed);
                console.log("Set localStorage key", key, !collapsed);
                //this.setState({ collapsed: !collapsed });
              }}
            >
              <Octicon className="expand_caret" name={caret} />
              <b>{categoryName}</b>
            </span>
          </div>
          <div className="col-sm-3">
            <MetricFrequency score={tree.get("meta_json")} />
          </div>
          <div className="col-sm-2"></div>
          <div className="col-sm-1">
            <AdminMenu {...this.props} onAddClick={this.copyTreeToCommit} links={this.dropDownLinks()} />
          </div>
        </div>
        <SubNodes {...this.props} hide={collapsed} />
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { path, commitNodes, cref } = ownProps;
  let tree = getNodeFromPath(state, path, commitNodes);

  // Get the sort for metrics below here
  let commit = cref?.get("latest_commit");
  let sortKey = commit?.getInPath("meta_json.sortKey") || "metric.name";
  sortKey = sortKey == "name" ? "metric.name" : sortKey;

  return { tree, sortKey };
};

export default connect(mapStateToProps)(TreeRow);
