import React, { useState } from "react";
import { ErrorBoundary } from "traec-react/errors";
import Traec from "traec";
import { confirmDelete } from "traec-react/utils/sweetalert";
import { AddRowForm, TableRow } from "./tableRow";
import { TableHeaders } from "./headers";
import { HeaderButtons, SaveButton, ClearButton } from "./headerButtons";
import { Hider } from "storybook-dashboard/utils";

export const DataTable = (props) => {
  let { fields, formName, data, setData, trackerId, commitId, doc, path, readOnly, formTemplate, assignments } = props;

  let initNewData = fields.reduce((acc, cur) => acc.set(cur.get("header"), ""), Traec.Im.Map());
  let [newData, setNewData] = useState(initNewData);

  let filename = `${formName}.csv`;
  const minTableWidth = fields?.size * 250;

  let rows = data.map((d, i) => (
    <TableRow
      key={i}
      index={i}
      fields={fields}
      data={d}
      setData={setData}
      readOnly={readOnly}
      removeHandler={(e) => {
        confirmDelete({
          text: `Are you sure you want to remove this row?`,
          onConfirm: () => {
            setData(data.delete(i));
          },
        });
      }}
      updateHandler={(rowData) => {
        setData(data.set(i, rowData));
      }}
      copyHandler={(rowData) => {
        setData(data.insert(i, rowData));
      }}
    />
  ));

  return (
    <ErrorBoundary>
      <HeaderButtons
        filename={filename}
        setData={setData}
        data={data}
        trackerId={trackerId}
        commitId={commitId}
        formTemplate={formTemplate}
        fields={fields}
        doc={doc}
        path={path}
        readOnly={readOnly}
        assignments={assignments}
      />
      <div style={{ clear: "both" }} />

      <div className="horizontal-scrollbar" style={{ overflowX: "auto", width: "100%" }}>
        <table className="table table-sm" style={{ minWidth: `${minTableWidth}px` }}>
          <thead>
            <TableHeaders fields={fields} readOnly={readOnly} />
          </thead>
          <tbody>
            {readOnly ? null : (
              <AddRowForm
                fields={fields}
                values={newData}
                onChangeHandler={(e) => {
                  setNewData(newData.merge({ [e.target.name]: e.target.value }));
                }}
                addHandler={(e) => {
                  setData(data.unshift(newData));
                  setNewData(initNewData);
                }}
              />
            )}
            {rows}
          </tbody>
        </table>
      </div>
      <br />
      <Hider hide={readOnly}>
        <ClearButton customStyles={`btn btn-sm btn-outline-danger my-3 mr-1`} setData={setData} />
        <SaveButton
          customStyles={`btn btn-sm btn-primary my-3 ml-1`}
          fields={fields}
          data={data}
          doc={doc}
          path={path}
          filename={filename}
          trackerId={trackerId}
          commitId={commitId}
          assignments={assignments}
        />
      </Hider>
    </ErrorBoundary>
  );
};
