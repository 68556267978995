import React from "react";
import { BSBtn, BSBtnDropdown, BSCard } from "traec-react/utils/bootstrap";
import Traec from "traec";
import { connect } from "react-redux";
import Im from "traec/immutable";
import Moment from "moment";

export const ProjectInviteRequestPanel = (props) => {
  new Traec.Fetch("project", "list").dispatch();
  new Traec.Fetch("project_discipline", "list", { projectId: props.projectId }).dispatch();

  const [state, setState] = React.useState({ showRequestPanel: false });

  const toggleRequest = () => {
    setState({ showRequestPanel: !state.showRequestPanel });
  };

  return (
    <BSCard
      title="Pending Requests"
      button={<RequestInvite onClick={toggleRequest} />}
      body={
        <div>
          {state.showRequestPanel ? (
            <RequestInviteForm
              availableProjects={props.availableProjects}
              availableDisciplines={props.projectDisciplines}
              toggleRequest={toggleRequest}
              projectId={props.projectId}
            />
          ) : null}
          <OutstandingRequests outstandingRequests={props.outstandingRequests} projectId={props.projectId} />
        </div>
      }
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  let { projectId } = ownProps;
  let availableProjects = state.getInPath("entities.projects.byId").filter((project) => {
    if (project.getIn(["meta_json", "allowSuppliers"])) {
      return project;
    }
  });

  let projectDisciplines = state.getInPath(`entities.projectObjects.byId.${projectId}.disciplines`);
  let requests = state.getInPath(`entities.projectObjects.byId.${projectId}.requests`);
  let outstandingRequests = requests
    ? requests.filter((request) => {
        if (request && request.get("status") === "outstanding") {
          return { request };
        }
      })
    : Im.Map();

  return { availableProjects, projectDisciplines, outstandingRequests };
};

export default connect(mapStateToProps)(ProjectInviteRequestPanel);

const OutstandingRequests = (props) => {
  try {
    let requestRows = props.outstandingRequests
      .valueSeq()
      .map((item, index) => (
        <RequestRow
          key={index}
          projectName={item.getIn(["client_project", "name"])}
          companyName={item.getIn(["client_project", "company", "name"])}
          disciplineName={item.getIn(["supplier_discipline", "name"])}
          requestDate={item.get("created")}
          requestId={item.get("uid")}
          clientProjectId={item.getIn(["client_project", "uid"])}
          ownProjectId={props.projectId}
        />
      ));
    if (requestRows.size) {
      requestRows = requestRows.toList().insert(0, <RequestRowHeader key={-1} />);
      return requestRows;
    } else {
      return <p>No pending requests</p>;
    }
  } catch (e) {
    return <p>No pending requests</p>;
  }
};

const RequestRowHeader = () => {
  return (
    <div className="row mb-2 mt-1 pt-1 pb-2" style={{ borderBottom: "1px solid lightgrey" }}>
      <div className="col-3">
        <b>Client</b>
      </div>
      <div className="col-2">
        <b>Company Name</b>
      </div>
      <div className="col-2">
        <b>Your Role</b>
      </div>
      <div className="col-4">
        <b>Request Date</b>
      </div>
    </div>
  );
};

const deleteRequest = (e, clientProjectId, requestId, ownProjectId) => {
  e.preventDefault();
  new Traec.Fetch("project_supplier", "delete", { clientProjectId, requestId, ownProjectId }).dispatch();
};

const RequestRow = (props) => {
  return (
    <div className="row">
      <div className="col-3">{props.projectName}</div>
      <div className="col-2">{props.companyName}</div>
      <div className="col-2">{props.disciplineName}</div>
      <div className="col-4">{Moment(props.requestDate).format("Do MMM YY")}</div>
      <div className="col-1">
        <BSBtnDropdown
          links={[
            {
              name: "Delete Request",
              onClick: (e) => deleteRequest(e, props.clientProjectId, props.requestId, props.ownProjectId),
            },
          ]}
        />
      </div>
    </div>
  );
};

const RequestInvite = (props) => {
  return <BSBtn text="Request New Client Access" onClick={props.onClick} />;
};

const RequestInviteForm = (props) => {
  const [state, setState] = React.useState({
    selectedProject: { name: "Select Client", uid: null },
    selectedDiscipline: { name: "Select Your Role", uid: null },
  });

  const updateSelectedProject = (e, project) => {
    setState({ ...state, selectedProject: { name: project.get("name"), uid: project.get("uid") } });
  };

  const updateSelectedDiscipline = (e, discipline) => {
    setState({ ...state, selectedDiscipline: { name: discipline.get("name"), uid: discipline.get("uid") } });
  };

  const resetForm = () => {
    setState({
      selectedProject: { name: "Select Client", uid: null },
      selectedDiscipline: { name: "Select Your Role", uid: null },
    });
    props.toggleRequest();
  };

  const sendSupplierRequest = (e) => {
    let fetch = new Traec.Fetch("project_supplier", "post", { projectId: state.selectedProject.uid });
    fetch.updateFetchParams({
      body: { supplier_discipline: state.selectedDiscipline.uid },
      postSuccessHook: () => resetForm(),
      nextHandlers: [
        () => {
          let nextFetch = new Traec.Fetch("project_supplier_sent", "list", { projectId: props.projectId });
          return nextFetch.params;
        },
      ],
    });
    fetch.dispatch();
  };

  let availableProjects = props.availableProjects
    .valueSeq()
    .sortBy((project) => project.getInPath("company.name"))
    .map((project) => {
      return {
        name: `${project.getInPath("company.name")} - ${project.get("name")}`,
        onClick: (e) => updateSelectedProject(e, project),
      };
    });

  let availableDisciplines = props.availableDisciplines.valueSeq().map((discipline) => {
    return { name: discipline.get("name"), onClick: (e) => updateSelectedDiscipline(e, discipline) };
  });

  const disableButton = () => {
    return !(state.selectedProject.uid && state.selectedDiscipline.uid);
  };

  return (
    <div className="row">
      <div className="col-3">
        <BSBtnDropdown
          scroll={true}
          floatStyle="float-left"
          header={state.selectedProject.name}
          links={availableProjects.toJS()}
        />
      </div>
      <div className="col-2">
        {state.selectedProject.uid ? (
          <BSBtnDropdown
            header={state.selectedDiscipline.name}
            links={availableDisciplines.toJS()}
            floatStyle="float-left"
          />
        ) : null}
      </div>
      <div className="col-7">
        <BSBtn text="Request Access" disabled={disableButton()} onClick={(e) => sendSupplierRequest(e)} />
      </div>
    </div>
  );
};
