import React from "react";
import Traec from "traec";
import Moment from "moment";
import { HTMLText } from "traec/utils/html";
import BaseFormConnected from "traec-react/utils/form";
import { titleDescriptionFields } from "traec-react/documents/form";
import Im from "traec/immutable";
import { getNodeFromPath, getPathChildren } from "traec/utils/nodes";
import { connect } from "react-redux";
import { CommentList } from "./index";
import { ErrorBoundary } from "traec-react/errors/handleError";
import { BSBtnDropdown } from "traec-react/utils/bootstrap/btnDropdown";
import { getLocale } from "AppSrc/utils";
import useApi from "storybook-dashboard/utils/fetching";

import { addComment } from "./index";

function ReplyButton(props) {
  return (
    <ErrorBoundary>
      <button
        className="btn btn-sm btn-outline-secondary mt-0 mb-0 pt-0 pb-0 float-right"
        onClick={(e) => addComment(e, props)}
      >
        <small>Reply</small>
      </button>
      <div style={{ clear: "both" }}></div>
    </ErrorBoundary>
  );
}

function CommentAttachments({ comment }) {
  let commentId = comment?.get("uid");
  let { data } = useApi(`/api/comment/${commentId}/`);
  let attachments = data?.get("attachments");

  if (!attachments || !attachments.size) {
    return null;
  }

  let attachmentLinks = attachments.map((i) => (
    <>
      <a href={i.get("url")}>{i.get("filename")}</a>{" "}
    </>
  ));

  return (
    <div>
      <span>
        <i>Attachments:</i>
      </span>{" "}
      {attachmentLinks}
    </div>
  );
}

const CommentItem = (props) => {
  let { comment, trackerId, commitId, path, subComments } = props;

  let dropdownLinks = [
    {
      name: "Edit",
      onClick: () => {
        console.log("Edit");
      },
    },
    {
      name: "Delete",
      onClick: () => {
        deleteComment();
      },
    },
  ];

  const deleteComment = () => {
    new Traec.Fetch("tracker_node", "delete", { trackerId, commitId, pathId: path }).dispatch();
  };

  return (
    <ErrorBoundary>
      <div className="m-0 ml-3 mb-3 comment-item">
        {/*<BSBtnDropdown links={dropdownLinks} />*/}
        <CommentText comment={comment} trackerId={trackerId} commitId={commitId} path={path} />
        <CommentAttachments comment={comment} />
        <CommentCreator comment={comment} />
        <ReplyButton {...props} />
        <hr className="mt-2 mb-2" />
        <CommentList trackerId={trackerId} commitId={commitId} comments={subComments} />
      </div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state, ownProps) => {
  let { commitId, path } = ownProps;

  let commitNodes = state.getInPath("entities.commitNodes");

  let node = getNodeFromPath(state, path, commitNodes.get(commitId), "comments");

  let subComments = null;

  if (node && commitNodes && path) {
    subComments = getPathChildren(state, path, commitNodes.get(commitId), "comments");
  }

  return { subComments };
};

export default connect(mapStateToProps)(CommentItem);

function CommentCreator({ comment }) {
  let created = Moment(comment.get("created")).format("YYYY-MM-DD HH:MM");

  const creator =
    `${comment.getInPath("creator.first_name")} ${comment.getInPath("creator.last_name")}`.trim() ||
    comment.getInPath("creator.username");

  return (
    <small className="m-0 p-0">
      By <b>{creator}</b> on {created}
    </small>
  );
}

const CommentText = (props) => {
  let { comment, path } = props;

  let text = comment.get("text");
  let title = comment.get("title");

  if (!path) {
    return null;
  }

  return (
    <ErrorBoundary>
      <p className="mb-0">
        <b>{title}</b>
      </p>
      <HTMLText text={text} style={{}} />
    </ErrorBoundary>
  );
};
