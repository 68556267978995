import { getCommitStageStatus, getCommitStatus } from "AppSrc/project/commits/row";
import { ApproveRejectForm } from "AppSrc/project/commits/approveReject";
import { Tooltip } from "react-tippy";
import React from "react";
import { BSBtn } from "traec-react/utils/bootstrap";
import { isRequiredInPeriod, isRequiredOnFreq } from "AppSrc/project/report/reportMetricRow";
import { reportingPeriodText } from "AppSrc/project/report/utils";
import Traec from "traec";
import { getScoreValuesByBaseMetric, getChildDocumentsWithStatus, getChildMetricScores } from "./utils";
import Octicon from "react-octicon";

export const Submit = (props) => {
  let {
    commit,
    refId,
    currentReportingPeriod,
    userProjectPermissions,
    projectId,
    projectBaseDisciplineMap,
    projectDisciplines,
    metricScores,
    baseMetrics,
    scoreValues, // Map with scoreValues indexed by baseMetricId (bmScoreValues)
    disableInputs,
    inputErrors,
    postCommit,
    patchCommit,
    needsRevision,
    sectionsComplete,
    categoryTrees,
    revalidate,
    commitNodes,
    documents,
    documentStatuses,
    rootTreePath,
  } = props;

  let commitStatus = getCommitStatus(commit);

  // Get if all of the sections are complete
  let report_layout = commit ? commit.getInPath("meta_json.report_layout") || "classic" : "classic";
  let includeSectionComplete = report_layout != "classic";
  let allSectionsComplete = includeSectionComplete ? sectionsComplete.every((i) => i === true) : true;

  if (commitStatus && commitStatus.startsWith("Not for")) {
    return <BSBtn text="Reporting On Hold" onClick={null} disabled={true} />;
  }

  if (!getCommitStageStatus(commit) && !(commitStatus === "Requires Revision")) {
    let currentPeriodString = currentReportingPeriod ? reportingPeriodText(currentReportingPeriod) : "";
    return (
      <ApproveRejectForm
        currentPeriodString={currentPeriodString}
        commit={commit}
        crefId={refId}
        userPermission={userProjectPermissions}
        projectId={projectId}
        projectBaseDisciplineMap={projectBaseDisciplineMap}
        projectDisciplines={projectDisciplines}
      />
    );
  }

  if (disableInputs) {
    return null;
  }

  let _docStatus = getChildDocumentsWithStatus(rootTreePath, commitNodes, documents, documentStatuses);

  let hasAllRequired = isAllRequiredSubmitted(
    getScoreValuesByBaseMetric(scoreValues),
    getChildMetricScores("", commitNodes, metricScores),
    baseMetrics,
    currentReportingPeriod,
    _docStatus,
    sectionsComplete
  );
  let sectionsWithErrors = (inputErrors || Traec.Im.Map()).toList().filter((i) => i.size);

  if (hasAllRequired !== true || sectionsWithErrors.size || !allSectionsComplete) {
    let errorText = getErrorText(hasAllRequired, inputErrors, sectionsComplete, categoryTrees, includeSectionComplete);
    return (
      <div className={"float-right"}>
        <Tooltip html={<div className={"text-left"}>{errorText}</div>} animateFill={false}>
          <BSBtn text="Submit report" disabled={true} noFloatRight={true} />
        </Tooltip>
        <Tooltip html={"Re-validate this form"} animateFill={false}>
          <Octicon
            name="sync"
            className="text-secondary ml-3"
            style={{ cursor: "pointer" }}
            onClick={(e) => revalidate()}
          />
        </Tooltip>
      </div>
    );
  }
  return <SubmitButton postCommit={postCommit} patchCommit={patchCommit} needsRevision={needsRevision} />;
};

export const SubmitButton = (props) => {
  let method = props.postCommit;
  if (props.needsRevision()) {
    method = props.patchCommit;
  }
  return <BSBtn text="Submit report" onClick={method} />;
};

const requiredErrors = (required) => {
  if (!Traec.Im.isImmutable(required)) {
    return Traec.Im.List();
  }
  return required.map((baseMetricName, i) => <li key={i}>{baseMetricName}</li>);
};

const getErrorText = (required, inputErrors, sectionsComplete, categoryTrees, includeSectionComplete) => {
  // Get a mapping from issue paths to real names
  let pathNameMap = categoryTrees
    ? categoryTrees.reduce((acc, cur) => acc.set(cur.get("_path"), cur.get("name")), Traec.Im.Map())
    : Traec.Im.Map();

  let sectionErrors = Traec.Im.List();

  if (sectionsComplete && sectionsComplete.size) {
    sectionErrors = sectionsComplete
      .map((complete, categoryPath, i) => {
        let categoryName = pathNameMap.get(categoryPath) || categoryPath;
        if (complete === true) {
          return null;
        } else {
          if (complete === false) {
            return includeSectionComplete ? <li key={i}>{categoryName} section not marked as complete</li> : null;
          } else {
            return (
              <React.Fragment key={i}>
                <li key={i}>{categoryName} section requires value for metrics:</li>
                <ul>{requiredErrors(complete)}</ul>
              </React.Fragment>
            );
          }
        }
      })
      .toList()
      .filter((i) => i);
  }

  let _inputErrors = inputErrors
    ? inputErrors
        .valueSeq()
        .filter((i) => Traec.Im.isImmutable(i) && i.get("name"))
        .map((item, i) => <div key={i}>{`${item.get("name")} was given a non-number value: ${item.get("value")}`}</div>)
    : Traec.Im.List();

  let _tooltip =
    sectionErrors.size || _inputErrors.size ? (
      <React.Fragment>
        {sectionErrors}
        {_inputErrors}
      </React.Fragment>
    ) : (
      <React.Fragment>
        <li>Please enter required data before submitting the report</li>
        {required.map((item, i) => (
          <ul key={i}>{item}</ul>
        ))}
      </React.Fragment>
    );

  return <ul className="tooltip-list">{_tooltip}</ul>;
};

const metricRequiredAndHasValue = (score, scoreValuesByBaseMetric, currentReportingPeriod, baseMetrics) => {
  let baseMetricId = score.getInPath("metric.uid") || score.get("metric");
  let isRequired = isRequiredInPeriod(score, currentReportingPeriod);
  let noReport = scoreValuesByBaseMetric.getIn([baseMetricId, "meta_json", "noReport"]);
  let value = scoreValuesByBaseMetric.getIn([baseMetricId, "value"]);
  let valueId = scoreValuesByBaseMetric.getIn([baseMetricId, "valueId"]);
  let hasValueOrValueId = noReport || (value !== null && value !== undefined) || valueId != null;
  return isRequired && !hasValueOrValueId ? baseMetrics?.getIn([baseMetricId, "name"]) : true;
};

const metricHasTrueValue = (score, scoreValuesByBaseMetric) => {
  let baseMetricId = score.getInPath("metric.uid") || score.get("metric");
  let noReport = scoreValuesByBaseMetric.getIn([baseMetricId, "meta_json", "noReport"]);
  let value = scoreValuesByBaseMetric.getIn([baseMetricId, "value"]);
  let hasValueOrValueId = noReport || (value !== null && value !== undefined && value != 0);
  return hasValueOrValueId;
};

const documentRequiredAndHasUpload = (documentStatus) => {
  if (!documentStatus) {
    return true;
  }
  let isRequired = documentStatus.getInPath("meta_json.isRequired");
  if (!isRequired) {
    return true;
  }
  let hasUpload = documentStatus.getInPath("status.current_object.url") != null;
  let noReport = documentStatus.getInPath("status.status.name") == "Not for Submission";
  return hasUpload || noReport ? true : `${documentStatus.get("name")} [${documentStatus.get("uid").substring(0, 8)}]`;
};

const getParentPaths = (path) => {
  let ends = Array.from(new Array(path.length / 7), (x, i) => (i + 1) * 7);
  return ends.map((i) => path.substring(0, i));
};

const childOfExcludedPath = (path, excludePaths) => {
  let parentPaths = Traec.Im.Set(getParentPaths(path));
  return excludePaths.intersect(parentPaths).size > 0;
};

export const isAllRequiredSubmitted = (
  scoreValuesByBaseMetric,
  metricScores,
  baseMetrics,
  currentReportingPeriod,
  documentStatuses,
  sectionsComplete
) => {
  if (!scoreValuesByBaseMetric || !metricScores || !baseMetrics) {
    return null;
  }

  // Exclude paths that are children of a complete section
  let excludeSectionPaths = Traec.Im.Set(sectionsComplete?.keys());

  // Get any scores not required in this reporting period (so we can later exclude children)
  let excludePaths = excludeSectionPaths.union(
    metricScores
      .toList()
      .filter((score) => (isRequiredOnFreq(score, currentReportingPeriod) || {}).dueThisReport === false)
      .map((i) => i.get("_path"))
      .filter((i) => i)
  );

  // Get any booleans that do not have a value (so we can later exclude children)
  let excludeBooleanPaths = Traec.Im.Set(
    metricScores
      .toList()
      .filter(
        (score) =>
          score.getInPath("meta_json.hideChildrenIfNullOrZero") && !metricHasTrueValue(score, scoreValuesByBaseMetric)
      )
      .map((i) => i.get("_path"))
      .filter((i) => i)
  );
  // Join the paths to exclude
  excludePaths = excludePaths.union(excludeBooleanPaths);

  // Include the boolean itself
  let includePaths = Traec.Im.Set(
    metricScores
      .toList()
      .filter(
        (score) =>
          score.getInPath("meta_json.hideChildrenIfNullOrZero") &&
          score.getInPath("required") &&
          !childOfExcludedPath(score.get("_path"), excludeSectionPaths)
      )
      .map((i) => i.get("_path"))
      .filter((i) => i)
  );

  // Check each of the metricScores and see if they have a value if required
  let isRequiredAndHasValue = metricScores
    .filter((i) => !(childOfExcludedPath(i.get("_path"), excludePaths) && !includePaths.has(i.get("_path"))))
    .toList()
    .map((score) => metricRequiredAndHasValue(score, scoreValuesByBaseMetric, currentReportingPeriod, baseMetrics));
  isRequiredAndHasValue = isRequiredAndHasValue.filter((i) => i !== true);

  // Check each of the documents and see if they have an upload (or n/a)
  let docsRequiredAndHasUpload = (documentStatuses || Traec.Im.List())
    .filter((i) => !childOfExcludedPath(i.get("_path"), excludePaths))
    .map((documentStatus) => documentRequiredAndHasUpload(documentStatus));
  docsRequiredAndHasUpload = docsRequiredAndHasUpload.filter((i) => i !== true);

  // concatenate the required lists (metrics and documents together)
  let requiredMetricsAndDocsHaveValue = isRequiredAndHasValue.concat(docsRequiredAndHasUpload);
  return requiredMetricsAndDocsHaveValue.size ? requiredMetricsAndDocsHaveValue : true;
};
