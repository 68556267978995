import React from "react";
import { connect } from "react-redux";
import Traec from "traec";

import TemplateBranchSelect from "./templateBranchSelect";
import { transcode } from "buffer";

/* SELECTOR FOR THE TRACKER TEMPLATE */

class TemplateTrackerSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    let fetch = new Traec.Fetch("tracker", "list", { onlyTemplates: true });
    fetch.dispatch();
  }

  render_branch_select() {
    let { tracker, trackerId, selectRefHandler } = this.props;
    return <TemplateBranchSelect tracker={tracker} trackerId={trackerId} refSelectHandler={selectRefHandler} />;
  }

  render_submit_button() {
    return (
      <button className="btn btn-sm btn-primary" onClick={this.props.addFromTemplateHandler}>
        Pull from template
      </button>
    );
  }

  render() {
    let { templates } = this.props;

    let opts = templates.toList().map((item, i) => (
      <option key={i} value={item.get("uid")}>
        {item.getInPath("project.name")}
      </option>
    ));
    opts = opts.unshift(
      <option key={-1} value="">
        Select a template
      </option>
    );

    return (
      <div className="row mt-3 mb-3">
        <div className="col-sm-5">
          <select
            className={`custom-select`}
            onChange={this.props.trackerSelectHandler}
            name="template"
            value={this.props.selectedTemplateId}
          >
            {opts}
          </select>
        </div>
        <div className="col-sm-5">{this.render_branch_select()}</div>
        <div className="col-sm-2">{this.render_submit_button()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { selectedTemplateId } = ownProps;
  // Get tracker templates
  let trackers = state.getInPath(`entities.trackers.byId`);
  let templates = trackers ? trackers.filter((item) => item.get("is_template")) : null;
  // Get the possible refs to chose from
  let tracker = state.getInPath(`entities.trackers.byId.${selectedTemplateId}`);
  let trackerId = tracker ? tracker.get("uid") : null;
  return { templates, tracker, trackerId };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(TemplateTrackerSelect);
