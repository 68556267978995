import React, { useState, useEffect } from "react";
import ReportRowErrorBoundary from "../error";
import { BSBtnDropdown } from "traec-react/utils/bootstrap";
import Traec from "traec";
import { ErrorBoundary } from "traec-react/errors";
import { validateFields } from "./validateFields";
import { Tooltip } from "react-tippy";
import Octicon from "react-octicon";

import { FormCellUpload, FileList } from "./fileUpload";

//Rendering the new completed form at the bottom after user presses add button
export const TableRow = (props) => {
  let { fields, data, copyHandler, updateHandler, removeHandler, readOnly } = props;
  let [edit, setEdit] = useState(false);
  let [_data, setData] = useState(data);

  console.log("AAAAAA data in TableRow", data?.toJS());

  let cells = fields.map((field, i) => {
    let header = field.get("header");
    let fieldType = field.get("type");
    let cellValue = data.get(header);
    //let fileList = JSON.parse(cellValue || null)
    console.log("AAAAAA Rendering cell", header, cellValue);
    let cellContent =
      fieldType == "file" ? <FileList fileList={JSON.parse(cellValue || null)} edit={edit} /> : cellValue;
    // let cellContent = cellValue

    return (
      <ErrorBoundary key={i}>
        <tr className="border-0" key={`questionRow:${i}`}>
          <td className="border-0 text-muted" key={`cellQuestion:${i}`}>
            {header}
          </td>
        </tr>
        <tr className="border-0" key={`answerRow:${i}`}>
          <td className="border-0 font-weight-bold" key={`cellAnswer:${i}`}>
            {cellContent}
          </td>
        </tr>
      </ErrorBoundary>
    );
  });

  if (edit) {
    return (
      <AddRowForm
        fields={fields}
        values={_data}
        data={data}
        btnName={"Finish editing"}
        addHandler={(e) => {
          updateHandler(_data);
          setEdit(false);
        }}
        onChangeHandler={(e) => {
          setData(_data.merge({ [e.target.name]: e.target.value }));
        }}
      />
    );
  }

  return (
    <ReportRowErrorBoundary>
      {readOnly ? null : (
        <tr className="border-0">
          <td>
            <div className="btn-group float-right" role="group" aria-label="edit-case-studies">
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                {<Octicon name="gear" />} Edit
              </button>
              <div className="dropdown-menu">
                <a
                  className="dropdown-item"
                  onClick={() => {
                    setData(data);
                    setEdit(true);
                  }}
                >
                  Edit answers
                </a>
                <a className="dropdown-item" onClick={() => copyHandler(data)}>
                  Duplicate
                </a>
                <a className="dropdown-item" onClick={removeHandler}>
                  Delete
                </a>
              </div>
            </div>
          </td>
        </tr>
      )}
      <ErrorBoundary>{cells}</ErrorBoundary>
    </ReportRowErrorBoundary>
  );
};

//Form questions function
const Header = (props) => {
  let { field } = props;
  let header = field.get("header");
  let description = field.get("description");

  if (description) {
    return (
      <td className="border-0">
        {header}
        <Tooltip html={description} animateFill={false} className={"text-left"}>
          <Octicon name="info" />
        </Tooltip>
      </td>
    );
  }

  return <td className="border-0">{header}</td>;
};

//Rendering form questions and input fields
export const AddRowForm = (props) => {
  let {
    fields,
    values,
    addHandler,
    onChangeHandler,
    readOnly,
    doc,
    path,
    trackerId,
    commitId,
    filename,
    assignments,
    data,
    setData,
    newData,
    setNewData,
    initNewData,
    btnName,
  } = props;

  let [errors, setErrors] = useState(Traec.Im.Map());

  if (!fields) {
    return null;
  }
  console.log("data in AddRowForm", data?.toJS());
  // useEffect(() => {
  //   postRowstoBackend(fields, data);
  // }, [data]);

  // const onChangeHandler = e => {
  //   setNewData(newData.merge({ [e.target.name]: e.target.value }));
  // };
  // const addHandler = e => {
  //   setData(data.unshift(newData));
  //   setNewData(initNewData);
  // };

  let inputs = fields.map((field, i) => (
    <React.Fragment key={i}>
      <tr key={`headerTr${i}`}>
        <Header key={`header:${i}`} field={field} />
      </tr>
      <tr key={`formCellTr${i}`}>
        <FormCellInput
          key={`formCell:${i}`}
          field={field}
          values={values}
          onChangeHandler={onChangeHandler}
          errors={errors}
        />
      </tr>
    </React.Fragment>
  ));

  let errorMessages = errors.keySeq().map((header, i) => (
    <p key={`err${i}`} className="text-danger m-0 p-0">
      <b key={`err:${i}`}>{header}</b>: {errors.get(header)}
    </p>
  ));

  console.log("assignments", assignments);
  return (
    <ErrorBoundary>
      {inputs}
      <tr>
        <td className="border-0 ">
          <a
            onClick={(e) => {
              let _errors = validateFields(fields, values);
              if (!_errors.size) {
                addHandler(e);
                // console.log("fields>>>", fields?.toJS());
                console.log("values>>>", values?.toJS());
                // console.log("data>>>", data?.toJS());
                setErrors(Traec.Im.Map());
                // postRowstoBackend(fields, data);
              } else {
                setErrors(_errors);
              }
            }}
            className="btn btn-primary text-white float-right"
          >
            {btnName ? btnName : "Save"}
          </a>
        </td>
        <td className="border-0"></td>
      </tr>
      {errors.size ? (
        <tr>
          <td colSpan={100}>{errorMessages}</td>
        </tr>
      ) : null}
    </ErrorBoundary>
  );
};

const FormCellInput = (props) => {
  let { field, values, errors, onChangeHandler } = props;
  let header = field.get("header");
  let fieldType = field.get("type");
  let error = errors.get(header);
  let value = values.get(header);

  console.log("FormCellInput", fieldType, header, value);

  if (fieldType === "selection") {
    return (
      <ErrorBoundary>
        <FormCellDropdown {...props} />
      </ErrorBoundary>
    );
  }

  if (fieldType === "multiselection") {
    return (
      <ErrorBoundary>
        <FormCellDropdown {...props} multi={true} />
      </ErrorBoundary>
    );
  }

  if (fieldType === "file") {
    return (
      <ErrorBoundary>
        <FormCellUpload {...props} />
      </ErrorBoundary>
    );
  }

  return (
    <td className="border-0">
      {fieldType === "textarea" ? (
        <textarea
          rows={"3"}
          type="text"
          className={`form-control form-control-sm ${error ? "is-invalid" : ""}`}
          id={header}
          name={header}
          value={value}
          onChange={onChangeHandler}
        />
      ) : (
        <input
          type="text"
          className={`form-control form-control-sm ${error ? "is-invalid" : ""}`}
          id={header}
          name={header}
          value={value}
          onChange={onChangeHandler}
        />
      )}
    </td>
  );
};

const FormCellDropdown = (props) => {
  let { field, values, errors, onChangeHandler, multi } = props;
  let header = field.get("header");
  let options = field.get("options") || Traec.Im.List();
  let error = errors.get(header);
  let value = values.get(header);

  let _options = multi ? options : options.unshift(null);
  _options = _options.map((option, i) => (
    <option key={`options:${i}`} value={option}>
      {option}
    </option>
  ));

  console.log("FormCellDropdown has raw value", value);
  let _value = multi ? (value || "").split(",").filter((i) => i) : value;
  console.log("FormCellDropdown has value", _value);

  return (
    <td className="border-0">
      <select
        type="text"
        className={`form-control form-control-sm ${error ? "is-invalid" : ""}`}
        id={header}
        name={header}
        value={_value}
        onChange={(e) => {
          console.log("FormCellDropdown Selected value", e.target.value);
          let _values = new Set(_value);
          if (_values.has(e.target.value)) {
            _values.delete(e.target.value);
          } else {
            _values.add(e.target.value);
          }
          onChangeHandler({
            target: {
              name: header,
              value: Array.from(_values).join(","),
            },
          });
        }}
        multiple={!!multi}
      >
        {_options}
      </select>
    </td>
  );
};
