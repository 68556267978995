import React, { useEffect, useState, useRef, useCallback } from "react";
import Traec from "traec";
import Im from "traec/immutable";
import { Editor as TinyMCE } from "@tinymce/tinymce-react";

import { ErrorBoundary } from "traec-react/errors";
import { connect } from "react-redux";
import { useDropzone } from "react-dropzone";
import { getPathChildren } from "traec/utils/nodes";
import CommentItem from "./commentItem";
import Octicon from "react-octicon";

import { setAndShowModal } from "AppSrc/utils";

const modalId = "CommonCommentModal001";

const getFormData = (e) => {
  e.preventDefault();
  let formData = new FormData(e.target);
  return Object.fromEntries(formData.entries());
};

const submitComment = ({ data, files, trackerId, commitId, path, setHidden }) => {
  console.log(`Posting comment to ${trackerId}, ${commitId}, ${path}`, data, files);

  let formData = new FormData();
  formData.append("type", "comment");
  formData.append("path", path);
  formData.append("node", JSON.stringify({ comment: { ...data } }));
  for (let file of files) {
    formData.append("attachments", file);
  }

  let fetch = new Traec.Fetch("tracker_node", "post", {
    trackerId,
    commitId,
    path,
  });

  fetch.updateFetchParams({
    body: formData,
    headers: { "content-type": null },
    rawBody: true,
    postSuccessHook: () => {
      if (setHidden) {
        setHidden(false);
      }
      $(`#${modalId}`).modal("hide");
    },
  });

  fetch.dispatch();
};

const tinyMCEConfig = {
  menubar: false,
  statusbar: false,
  content_css: "/static/bootstrap/css/bootstrap.css",
  plugins: "autolink link image lists print preview",
  block_formats: "Paragraph=p;Header 3=h3;Header 4=h4;Header 5=h5;",
  toolbar:
    "bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | sup sub | formatselect | removeformat",
};

function CommentForm(props) {
  const [files, setFiles] = useState(Im.List());
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => setFiles(files.concat(Im.fromJS(acceptedFiles || []))),
  });
  const editorRef = useRef(null);

  const fileList = files.map((file, i) => (
    <li key={i}>
      {file.path} - {(file.size / 1024).toFixed(2)} kB
      <span className="float-right" onClick={() => setFiles(files.delete(i))} style={{ cursor: "pointer" }}>
        remove
      </span>
    </li>
  ));

  return (
    <form
      onSubmit={(e) => {
        let data = { ...getFormData(e), ...{ text: editorRef.current.getContent() } };
        submitComment({ data, files: files?.toJS(), ...props });
      }}
    >
      <div className="form-group">
        <label htmlFor="title">Title</label>
        <input type="text" className="form-control" id="title" name="title" aria-describedby="title" />
      </div>
      <div className="form-group">
        <label htmlFor="text">Text</label>
        <TinyMCE
          onInit={(evt, editor) => (editorRef.current = editor)}
          name={"text"}
          initialValue={""}
          init={tinyMCEConfig}
        />
      </div>
      <h4>Attachments</h4>
      <div {...getRootProps({ className: "alert alert-secondary dropzone text-center" })}>
        <input {...getInputProps()} />
        Drag and drop or click here to add attachments
      </div>
      <ul>{fileList}</ul>
      <button type="submit" className="btn btn-sm btn-outline-secondary float-right">
        Submit
      </button>
    </form>
  );
}

export const addComment = (e, props) => {
  e.preventDefault();

  setAndShowModal(modalId, {
    title: "Add a comment",
    body: <CommentForm {...props} />,
  });
};

export const CommentList = (props) => {
  let { trackerId, commitId, comments } = props;

  if (!comments) {
    return null;
  }

  let commentItems = comments
    .valueSeq()
    .map((comment, i) => (
      <CommentItem key={i} path={comment.get("_path")} comment={comment} trackerId={trackerId} commitId={commitId} />
    ));

  return <ErrorBoundary>{commentItems}</ErrorBoundary>;
};

function AddRootCommentButton(props) {
  return (
    <ErrorBoundary>
      <button
        className="btn btn-sm btn-outline-secondary mt-0 mb-0 pt-0 pb-0 float-right"
        onClick={(e) => addComment(e, props)}
      >
        Add a Comment
      </button>
      <div style={{ clear: "both" }} />
    </ErrorBoundary>
  );
}

function ExpandCommentsButton({ subComments, setHidden }) {
  return (
    <ErrorBoundary>
      <span className="float-right" style={{ cursor: "pointer" }} onClick={(e) => setHidden(false)}>
        {subComments.size} comment{subComments.size > 1 ? "s" : ""} <Octicon name="chevron-down" />
      </span>
      <div style={{ clear: "both" }} />
    </ErrorBoundary>
  );
}

const Comments = (props) => {
  let { path, trackerId, commitId, subComments } = props;
  let [hidden, setHidden] = useState(true);

  if (!path) {
    return null;
  }

  //
  if (hidden) {
    if (!subComments.size) {
      return <AddRootCommentButton {...props} setHidden={setHidden} />;
    } else {
      return <ExpandCommentsButton {...props} setHidden={setHidden} />;
    }
  }

  return (
    <ErrorBoundary>
      <AddRootCommentButton {...props} setHidden={setHidden} />

      <ErrorBoundary>
        <CommentList trackerId={trackerId} commitId={commitId} comments={subComments} />
      </ErrorBoundary>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state, ownProps) => {
  let { commitNodes, path } = ownProps;

  let subComments = null;

  if (commitNodes && path) {
    subComments = getPathChildren(state, path, commitNodes, "comments");
  }

  return { subComments };
};

export default connect(mapStateToProps)(Comments);
