import Im from "traec/immutable";
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Traec from "traec";
import Octicon from "react-octicon";

import { getProjectProps, BreadCrumb } from "AppSrc/project/utils";
import CommitTableRow from "./row";
import { BSBtnDropdown } from "traec-react/utils/bootstrap/btnDropdown";

const sortFuncs = {
  reportingPeriod: (sortDir) => (a, b) => {
    const dir = sortDir === "asc" ? 1 : -1;
    const itemA = a?.get("reporting_period_data")?.get("startDate");
    const itemB = b?.get("reporting_period_data")?.get("startDate");

    if (new Date(itemA) < new Date(itemB)) {
      return -dir;
    } else if (new Date(itemA) > new Date(itemB)) {
      return dir;
    }

    return 0;
  },
  lastUpdated: (sortDir) => (a, b) => {
    const dir = sortDir === "asc" ? 1 : -1;
    const itemA = a?.get("meta_json")?.get("lastUpdateOn");
    const itemB = b?.get("meta_json")?.get("lastUpdateOn");

    if (new Date(itemA) < new Date(itemB)) {
      return -dir;
    } else if (new Date(itemA) > new Date(itemB)) {
      return dir;
    }

    return 0;
  },
  status: (sortDir) => (a, b) => {
    const dir = sortDir === "asc" ? 1 : -1;
    const itemA = a?.get("meta_json")?.get("status")?.toLowerCase();
    const itemB = b?.get("meta_json")?.get("status")?.toLowerCase();

    if (itemA < itemB) {
      return -dir;
    } else if (itemA > itemB) {
      return dir;
    }

    return 0;
  },
  lastUpdateBy: (sortDir) => (a, b) => {
    const dir = sortDir === "asc" ? 1 : -1;
    const itemA = (
      a?.get("meta_json")?.get("lastUpdateBy")?.get("first_name") +
      " " +
      a?.get("meta_json")?.get("lastUpdateBy")?.get("last_name")
    ).toLowerCase();
    const itemB = (
      b?.get("meta_json")?.get("lastUpdateBy")?.get("first_name") +
      " " +
      b?.get("meta_json")?.get("lastUpdateBy")?.get("last_name")
    ).toLowerCase();

    if (itemA < itemB) {
      return -dir;
    } else if (itemA > itemB) {
      return dir;
    }

    return 0;
  },
  comment: (sortDir) => (a, b) => {
    const dir = sortDir === "asc" ? 1 : -1;
    const itemA = a?.get("comment")?.toLowerCase();
    const itemB = b?.get("comment")?.toLowerCase();

    if (itemA < itemB) {
      return -dir;
    } else if (itemA > itemB) {
      return dir;
    }

    return 0;
  },
};

function CommitTableRows(props) {
  let { commits, sortBy, sortDir } = props;
  commits = commits?.sortBy((commit) => commit.get("created")).reverse() || Traec.Im.List();
  return [...commits]
    .sort(sortBy ? sortFuncs[sortBy](sortDir) : undefined)
    .map((commit, i) => <CommitTableRow key={i} commit={commit} {...props} />);
}

class WPCommits extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortBy: null,
      sortDir: "asc",
      hideNullReportingPeriod: true,
      fetchedLog: false,
      fetchedUrls: {},
      fetchedReportingPeriods: false,
      fetchedMembers: false,
      nameFormParams: {
        stateParams: {},
        fetchParams: {},
        initFields: {},
      },
    };

    this.requiredFetches = [
      //new Traec.Fetch('project_reporting_periods', 'list'),
      new Traec.Fetch("project_discipline", "list"),
      new Traec.Fetch("tracker_ref_commit", "list"),
      new Traec.Fetch("project_reporting_periods", "list"),
    ];
  }

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  componentDidUpdate() {
    Traec.fetchRequiredFor(this);
  }

  getUrlParams() {
    const { cref, crefId } = this.props;
    if (!cref) {
      return {};
    }
    const trackerId = cref.get("tracker");
    const commitId = cref.get("latest_commit").get("uid");
    const refId = crefId;
    return { refId, commitId, trackerId };
  }

  setSortBy(column) {
    this.setState({ sortBy: column });
  }

  toggleSortDir() {
    const { sortDir } = this.state;
    if (sortDir === "asc") {
      this.setState({ sortDir: "desc" });
    } else {
      this.setState({ sortDir: "asc" });
    }
  }

  render() {
    const { company, projectId, project, tracker, rootTree, cref, isRootRef, commits } = this.props;
    let { hideNullReportingPeriod: hideNRP } = this.state;
    if (!project || !tracker) {
      return null;
    }

    const titles = [
      { key: "reportingPeriod", title: "Reporting Period" },
      { key: "status", title: "Status" },
      { key: "lastUpdated", title: "Last Updated" },
      { key: "lastUpdateBy", title: "Last Update By" },
      { key: "comment", title: "Comment" },
      { key: "", title: "" },
    ]; //"Supplier(s)" used to be first column

    const headCols = titles.map((title, i) => (
      <th
        key={i}
        scope="col"
        style={{ cursor: "pointer" }}
        onClick={() => {
          this.setSortBy(title.key);
          this.toggleSortDir();
        }}
      >
        {title.title}{" "}
        {title.key === this.state.sortBy ? (
          <Octicon name={this.state.sortDir === "asc" ? "chevron-down" : "chevron-up"} />
        ) : (
          <Octicon name="chevron-up" style={{ opacity: 0.5 }} />
        )}
      </th>
    ));

    return (
      <React.Fragment>
        <h3>Reports Submitted</h3>
        <BreadCrumb company={company} project={project} cref={cref} isRootRef={isRootRef} />
        <BSBtnDropdown
          links={[
            {
              name: `${hideNRP ? "Show" : "Hide"} null Reporting Periods`,
              onClick: () => {
                this.setState({ hideNullReportingPeriod: !hideNRP });
              },
            },
          ]}
        />
        {/* Render the headers of the table */}
        <table className="table table-sm">
          <thead>
            <tr>{headCols}</tr>
          </thead>
          {/* Render the rows */}
          <tbody>
            <CommitTableRows
              {...this.props}
              sortBy={this.state.sortBy}
              sortDir={this.state.sortDir}
              hideNullReportingPeriod={hideNRP}
            />
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  //const { _projectId, _refId } = ownProps.match.params;
  const { projectId, refId } = Traec.utils.getFullIds(state, ownProps.match.params);

  let { company, project, tracker, trackerId, cref, crefId, isRootRef } = getProjectProps(state, projectId, refId);
  // Get the commit history
  let commitIds = state.getInPath(`entities.categoryCommits.byId.${crefId}`);
  let path = crefId
    ? `entities.projectReportingPeriods.ref.${crefId}.byId.${projectId}`
    : `entities.projectReportingPeriods.byId.${projectId}`;
  let projectReportingPeriods = state.getInPath(path);
  let commits = commitIds
    ? commitIds
        .map((itemId) => {
          let commit = state.getInPath(`entities.commits.byId.${itemId}`);
          if (!(commit.get("ref") == crefId)) {
            return null;
          }
          let reportPeriodId = commit.get("reporting_period");
          if (reportPeriodId) {
            let rpObj = projectReportingPeriods ? projectReportingPeriods.get(`${reportPeriodId}`) : null;
            commit = commit.set("reporting_period", rpObj);
          }
          return commit;
        })
        .filter((i) => i)
    : Im.List();
  // Get a map of Project Disciplines
  let projectDisciplines = state.getInPath(`entities.projectObjects.byId.${projectId}.disciplines`) || Im.List();
  let projectBaseDisciplineMap = projectDisciplines.reduce((obj, i) => obj.set(i.get("base_uid"), i), Im.Map());
  // Get the user project permission object
  let userPermission = state.getInPath(`entities.projectObjects.byId.${projectId}.userPermission`);
  // Add this to props
  return {
    userPermission,
    projectId,
    project,
    company,
    tracker,
    trackerId,
    cref,
    crefId,
    refId,
    isRootRef,
    commits,
    projectReportingPeriods,
    projectBaseDisciplineMap,
    projectDisciplines,
  };
};

export default connect(mapStateToProps)(WPCommits);
