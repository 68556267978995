import Traec from "traec";

export * from "./getters";
export * from "./combiners";

export const copyToCommitHandler = (props) => {
  // Get the tracker and commit ID to the new commit
  let { copyToCommit, path: from_path } = props;
  let { trackerId, commitId } = copyToCommit;
  let fetch = new Traec.Fetch("tracker_node", "post", { trackerId, commitId });
  fetch.updateFetchParams({
    body: { from_path },
  });
  fetch.dispatch();
};
