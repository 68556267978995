import React from "react";
import { connect } from "react-redux";
import Traec from "traec";

import { loadConvFacts, getTrackerProps } from "AppSrc/project/utils";

/* SELECTOR FOR THE TRACKER BRANCH */

class TemplateBranchSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidUpdate(prevProps) {
    let { trackerId } = this.props;
    if (prevProps.trackerId != trackerId) {
      new Traec.Fetch("tracker_branch", "list", { trackerId }).dispatch();
    }
    let { refSelectHandler, convFactRef } = this.props;
    if (
      (!prevProps.convFactRef && convFactRef) ||
      (prevProps.convFactRef && prevProps.convFactRef.equals(convFactRef))
    ) {
      refSelectHandler({
        preventDefault: () => {
          return null;
        },
        target: { value: this.props.convFactRef.getInPath("latest_commit.uid") },
      });
    }
  }

  render() {
    let { revisionRefs, convFactRef } = this.props;
    //console.log("RENDERING BRANCH SELECT", revisionRefs, convFactRef)

    if (!revisionRefs) {
      return null;
    }

    const options = revisionRefs.map((cref, i) => (
      <option key={i} value={cref.getInPath("latest_commit.uid")}>
        {cref.get("name")} ({cref.get("uid").substring(0, 8)})
      </option>
    ));
    return (
      <React.Fragment>
        <select
          className={`custom-select`}
          onChange={this.props.refSelectHandler}
          defaultValue={convFactRef.getInPath("latest_commit.uid")}
        >
          {options}
        </select>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { trackerId } = ownProps;
  let { cref } = getTrackerProps(state, trackerId);
  let commitId = cref ? cref.getInPath("latest_commit.uid") : null;
  // Get possible conversion factor groups for this commit
  let revisionRefs = Traec.Im.List();

  return { revisionRefs };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(TemplateBranchSelect);
